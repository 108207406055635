import { graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';
import Layout from '~/components/Layout';
import Seo from 'gatsby-plugin-wpgraphql-seo';
// Elements
import { WebinarPlay, WebinarTime, WebinarDate, GreenCheck } from '../components/elements/Icon';
import Image from '../components/elements/Image';
import { Link } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';
import { BlueGlow } from '../components/Glow';
// Blocks
import HubspotForm from '../components/elements/forms/HubspotForm';
import ArticleRenderer from '../components/article/ArticleRenderer';
import DialogHandler from '../components/elements/modal/DialogHandler';

export default function SingleWebinar({ data: { wpWebinar, wpPage, wp } }) {
  return (
    <Layout wp={wp} hideFooter={false}>
      <Seo post={wpWebinar} title={wpWebinar?.seo?.title || wpWebinar?.title} />
      <WebinarMasthead wpWebinar={wpWebinar} />
      <WebinarContent wpWebinar={wpWebinar} />
    </Layout>
  );
}

function WebinarMasthead({ wpWebinar }) {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const isUpcoming = wpWebinar?.postTypeSingleWebinar?.webinarDetails?.isUpcoming;

  // Modal Handler
  const [isOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (!isUpcoming && formSubmitted) {
      openModal();
    }
  }, [isUpcoming, formSubmitted]);

  return (
    <section className="bg-navy text-white pt-32 pb-10 md:pb-0 lg:pt-[12.125rem] relative">
      {/* Glow */}
      <div className="absolute inset-0 overflow-hidden z-0">
        <BlueGlow
          classes={`absolute bottom-[20%] md:bottom-[-50%] xl:bottom-[-35%] left-[-15%] lg:left-[35%] xl:left-[25%] w-[1000px] h-[1000px] xl:w-[1200px] xl:h-[1200px] opacity-[.6]`}
          stopTwo={'20%'}
          stopThree={'60%'}
        />
      </div>
      <div className="container relative z-10 md:grid md:gap-x-8 md:grid-cols-2 gap-y-8 md:gap-y-0 relative">
        <div className="pb-10 md:pb-16 lg:pb-[7.3125rem] max-w-[31.875rem]">
          <span
            className="mb-4 block md:mb-6 t-subheading uppercase font-semibold text-teal !font-body t-14 tracking-widest"
            dangerouslySetInnerHTML={{ __html: wpWebinar?.postTypeSingleWebinar?.webinarDetails?.webinarSubheading }}
          />
          <h1 className="t-58 font-medium mb-3 md:mb-4" dangerouslySetInnerHTML={{ __html: wpWebinar?.title }} />
          <p
            className="t-18 opacity-[.8]"
            dangerouslySetInnerHTML={{ __html: wpWebinar?.postTypeSingleWebinar?.webinarDetails?.webinarDescription }}
          />
          {/* Details */}
          <div className="mt-6 md:mt-8 space-y-4 sm:space-y-0 sm:flex sm:space-x-3 md:space-x-5 lg:space-x-8 items-center">
            <WebinarDetail label={wpWebinar?.postTypeSingleWebinar?.webinarDetails?.webinarDate} icon="date" />
            <WebinarDetail label={wpWebinar?.postTypeSingleWebinar?.webinarDetails?.webinarTime} icon="time" />
          </div>
        </div>
        {/* Form */}
        <div className="relative">
          <div className="md:absolute md:top-0 w-full">
            {/* Video */}
            <div className="bg-white/10 p-4 rounded-t-[.75rem]">
              <button
                type="button"
                onClick={() => setIsOpen(!isUpcoming && formSubmitted ? true : false)}
                className={`relative overflow-hidden rounded-[.375rem] aspect-video w-full ${
                  formSubmitted ? 'group' : 'pointer-events-none'
                }`}
              >
                <div className="aspect-square w-[72px] absolute inset-0 m-auto rounded-full bg-blue p-6 z-10 group-hover:scale-[1.1] transition-transform duration-300">
                  <WebinarPlay />
                </div>
                <div
                  className={`absolute inset-0 w-full h-full bg-navy opacity-50 z-[5] group-hover:opacity-[.3] transition-opacity duration-300`}
                />
                <Image
                  image={wpWebinar?.featuredImage?.node}
                  className="w-full h-full object-cover"
                  objectFit="cover"
                />
              </button>
              <DialogHandler
                isOpen={isOpen}
                closeModal={closeModal}
                webinarEmbed={wpWebinar?.postTypeSingleWebinar?.webinarDetails?.webinarEmbed}
              />
            </div>
            <div className="border border-black/10 rounded-b-[.75rem] bg-white p-6 md:p-8 pb-8 md:pb-10 text-black">
              <h2 className="mb-6 md:mb-8 t-40" dangerouslySetInnerHTML={{ __html: 'Register to watch' }} />
              <AnimatePresence exitBeforeEnter>
                {formSubmitted ? (
                  <motion.div
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3, ease: 'easeInOut' }}
                  >
                    <p
                      className="t-18"
                      dangerouslySetInnerHTML={{
                        __html: wpWebinar?.postTypeSingleWebinar?.webinarDetails?.thankYouMessage,
                      }}
                    />
                  </motion.div>
                ) : (
                  <motion.div
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 30, opacity: 0 }}
                    transition={{ duration: 0.3, ease: 'easeInOut' }}
                    className="webinar-form"
                  >
                    <HubspotForm
                      region={wpWebinar?.postTypeSingleWebinar?.webinarDetails?.gatedForm?.region}
                      portalId={wpWebinar?.postTypeSingleWebinar?.webinarDetails?.gatedForm?.portalId}
                      formId={wpWebinar?.postTypeSingleWebinar?.webinarDetails?.gatedForm?.formId}
                      setFormSubmitted={setFormSubmitted}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function WebinarDetail({ label, icon }) {
  return (
    <div className="flex items-center space-x-4">
      <div className="shrink-0 aspect-square w-6 h-6 flex items-center justify-center">
        {icon === 'date' ? <WebinarDate /> : <WebinarTime />}
      </div>
      <span className="t-18 font-normal" dangerouslySetInnerHTML={{ __html: label }} />
    </div>
  );
}

function WebinarContent({ wpWebinar }) {
  return (
    <section className="pt-10 md:pt-6 lg:pt-[4.5rem]">
      <div className="container md:grid md:grid-cols-2">
        {/* Article */}
        <div className="space-y-10 md:space-y-12 lg:space-y-[4.5rem] max-w-[31.875rem]">
          {/* Summary */}
          <div>
            <h2
              className="t-42 font-medium mb-6 md:mb-[40px]"
              dangerouslySetInnerHTML={{ __html: wpWebinar?.postTypeSingleWebinar?.summary?.heading }}
            />
            <ul className="grid gap-y-5 md:gap-y-8">
              {wpWebinar?.postTypeSingleWebinar?.summary?.checklist?.map((item, l) => (
                <li key={l} className="flex space-x-4 md:space-x-6">
                  <div className="shrink-0 rounded-[.375rem] w-8 h-8 sm:w-[2.375rem] sm:h-[2.375rem] bg-[#12C0C0]/10 p-1 flex items-center justify-center">
                    <GreenCheck />
                  </div>
                  <span className="t-18 text-navy" dangerouslySetInnerHTML={{ __html: item?.listItem }} />
                </li>
              ))}
            </ul>
          </div>
          <ArticleRenderer
            content={wpWebinar?.postTypeSingleWebinar?.description?.articleBlocks}
            prefix={'Webinar_Posttypesinglewebinar_Description_ArticleBlocks_'}
          />
        </div>
      </div>
    </section>
  );
}

export const pageQuery = graphql`
  query Webinar($id: String!) {
    wp {
      ...GeneratedWp
      header {
        globalUpdatedHeader {
          fieldGroupName
          headerUpdated {
            topLevelNavigation {
              link {
                title
                target
                url
              }
              addMenu
              subMenu {
                tabs {
                  heading
                  description
                  menuType
                  columnMenu {
                    columns {
                      heading
                      cards {
                        image {
                          ...Image
                        }
                        link {
                          title
                          url
                        }
                        description
                      }
                    }
                  }
                  rowMenu {
                    heading
                    cards {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                  resourcesMenu {
                    listSubheading
                    listItems {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                    }
                    cardsSubheading
                    cardItems {
                      thumbnail {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                }
              }
            }
            buttons {
              textLink {
                target
                title
                url
              }
              buttonLink {
                target
                title
                url
              }
            }
            socials {
              image {
                ...Image
              }
              link {
                target
                title
                url
              }
            }
          }
        }
      }
    }
    wpWebinar(id: { eq: $id }) {
      title
      uri
      date
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          srcSet
          sourceUrl
          localFile {
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          srcSet
          localFile {
            publicURL
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      featuredImage {
        node {
          altText
          publicUrl
          sourceUrl
          localFile {
            publicURL
          }
        }
      }
      postTypeSingleWebinar {
        webinarDetails {
          isUpcoming
          webinarEmbed
          thankYouMessage
          webinarDate
          webinarTime
          gatedForm {
            region
            portalId
            formId
          }
          webinarSubheading
          webinarDescription
        }
        summary {
          heading
          checklist {
            listItem
          }
        }
        description {
          articleBlocks {
            ... on WpWebinar_Posttypesinglewebinar_Description_ArticleBlocks_Text {
              fieldGroupName
              heading
              text
            }
            ... on WpWebinar_Posttypesinglewebinar_Description_ArticleBlocks_Image {
              fieldGroupName
              image {
                ...Image
              }
            }
            ... on WpWebinar_Posttypesinglewebinar_Description_ArticleBlocks_Quote {
              fieldGroupName
              quote
              addContext
              by {
                name
                role
              }
            }
            ... on WpWebinar_Posttypesinglewebinar_Description_ArticleBlocks_Bullets {
              fieldGroupName
              heading
              bullets {
                text
              }
            }
          }
        }
      }
    }
  }
`;
